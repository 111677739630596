/* Navbar container */
.navbar {
  background-color: #ffffff;
  border-radius: 50px;
  margin: 20px auto;
  max-width: 800px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: border-radius 0.1s ease;
}

/* Remove rounded edges when menu is open */
.navbar.menu-open {
  border-radius: 0;
}

/* Centering the navbar content */
.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
}

/* Navbar logo */
.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: auto;
  margin-left: 20px;
  color: #333;
  text-decoration: none;
}

/* Menu icon (hamburger) */
.menu-icon {
  display: none;
  font-size: 1.8rem;
  color: #333;
  cursor: pointer;
  margin-left: auto;
}

/* Navigation links */
.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 10;
}

/* Navigation items */
.nav-links li {
  margin: 0 10px;
}

/* Navigation links styling */
.nav-item {
  text-decoration: none;
  color: #333;
  padding: 8px 16px;
  border-radius: 25px;
  transition: background-color 0.3s, color 0.3s;
}

/* Hover effect */
.nav-item:hover {
  background-color: #004e92;
  color: #fff;
}

.nav-item.active {
  background-color: #013a6b;
  color: #fff;
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .nav-links {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 0 20px;      
    box-sizing: border-box; 
    background-color: #ffffff;
    border-radius: 0 0 30px 30px;
    flex-direction: column;
    align-items: center;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }

  .nav-links.active {
    max-height: 300px; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .navbar.menu-open .nav-links {
    border-radius: 0;
  }

  .nav-links li {
    margin: 10px 0;
  }

  .navbar-container {
    padding: 10px 20px;
  }
}


/* Skills section container */
.skills {
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
  /* If you want a mild background behind the skills to contrast the gradient:
     background-color: rgba(0, 0, 0, 0.4);
     border-radius: 8px;
     Or keep it transparent if it looks good on your gradient.
  */
}

/* Center text inside the skills container */
.skills-content {
  text-align: center;
}

/* Skills heading */
.skills-heading {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #fff; /* White stands out on a dark or gradient background */
}

/* Skills list layout */
.skills-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

/* Each skill "chip" */
.skill-item {
  padding: 0.75rem 1.5rem;
  border: 1px solid #fff; 
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.1); 
  color: #fff;
  cursor: default;
  transition: background-color 0.3s, border-color 0.3s;
}

.skill-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* 
  OUTSIDE text (before parentheses):
  White (#fff) for consistency with the heading & dark theme 
*/
.skill-outside {
    /* color: #db5e0a;  */
    font-weight: bold; /* optional, if you want it more emphasized */  
}

/* 
  INSIDE text (within parentheses):
  Use the same accent blue (#1e90ff) as your floating button 
  for a nice tie-in 
*/
/* .skill-inside {
  color: #1e90ff;
} */

/* Responsive adjustments for smaller screens */
@media (max-width: 600px) {
  .skills-heading {
    font-size: 2rem;
  }
  .skill-item {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
}

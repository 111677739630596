.company-logo {
  width: 70px; 
  height: 70px;
  border-radius: 50%;
  object-fit: cover; 
  border: 2px solid #1e90ff; 
  margin-right: 10px;
}
.experience-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.experience-page {
  padding: 4rem 2rem;
  background: linear-gradient(to bottom, #000428, #004e92);
}

.experience-heading {
  text-align: center;
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 3rem;
}
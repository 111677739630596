.contact-page {
    padding: 4rem 2rem;
    /* background: linear-gradient(to bottom, #000428, #004e92);  */
  }
  
  .contact-heading {
    text-align: center;
    font-size: 2.5rem;
    color: #ffffff;
    margin-bottom: 3rem;
  }
  
  .contact-links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
  }
  
  .contact-link {
    display: flex;
    align-items: center;
    color: #ffffff;
    text-decoration: none;
    font-size: 1.2rem;
    transition: color 0.2s;
  }
  
  .contact-link:hover {
    color: #1e90ff; 
  }
  
  .contact-icon {
    margin-right: 0.5rem;
    font-size: 2rem;
  }
  
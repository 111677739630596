.resume-container {
  width: 100%;
  margin: 0 auto;
  padding: 2rem 1rem;
  box-sizing: border-box;
  text-align: center; 
}

.resume-image {
  max-width: 800px; 
  width: 100%;      
  height: auto;     
  display: block;  
  margin: 0 auto;   
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.resume-download {
  text-align: center;
}


.resume-download .btn:hover {
  background-color: #2980b9;
}

.btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #0077cc;
  color: #fff;
  text-decoration: none;
  border-radius: 25px;
  transition: background-color 0.3s;
}


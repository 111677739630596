.contribution-graph-container {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  .contribution-graph-card {
    background-color: #0d1117;
    border-radius: 8px;
    padding: 20px;
    width: fit-content;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .contribution-graph-card h2 {
    color: #c9d1d9;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .github-link {
    color: #c9d1d9;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .graph-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .month-labels {
    display: flex;
    align-items: center;
    margin-left: 14px;
    overflow-x: auto;
  }
  
  .day-label-placeholder {
    min-width: 14px;
    margin-right: 0;
  }
  
  .month-label {
    width: 11px;
    margin: 1px;
    font-size: 10px;
    text-align: center;
    color: #8b949e;
  }
  
  .graph-content {
    display: flex;
    flex-direction: column;
  }
  
  .row {
    display: flex;
    align-items: center;
  }
  
  .day-label {
    min-width: 14px;
    margin-right: 0;
    text-align: right;
    font-size: 10px;
    color: #8b949e;
  }
  
  .day {
    width: 11px;
    height: 11px;
    margin: 1px;
    background-color: #161b22;
    border-radius: 2px;
  }
  
  .day:hover {
    outline: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .intensity-0 {
    background-color: #161b22;
  }
  
  .intensity-1 {
    background-color: #0e4429;
  }
  
  .intensity-2 {
    background-color: #006d32;
  }
  
  .intensity-3 {
    background-color: #26a641;
  }
  
  .intensity-4 {
    background-color: #39d353;
  }
  
  @media (max-width: 600px) {

    .contribution-graph-container {
      padding: 10px;
      transform: scale(0.9);
    }
  
    .contribution-graph-card {
      padding: 15px;
      transform: scale(0.9);
    }
  
    .contribution-graph-card h2 {
      font-size: 16px;
      margin-bottom: 15px;
      transform: scale(0.9);
    }
  
    .month-labels {
      margin-left: 10px;
      overflow-x: auto;
      transform: scale(0.9);
    }
  
    .month-label {
      width: 8px;
      font-size: 8px;
      transform: scale(0.9);
    }
  
    .day {
      width: 8px;
      height: 8px;
      margin: 0.5px;
      transform: scale(0.9);
    }
  }
  
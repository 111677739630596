/* Container styling */
.projects-container {
  padding: 4rem 2rem;
}

.projects-heading {
  text-align: center;
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 3rem;
}

/* Remove default link styling */
.project-link {
  text-decoration: none;
}

/* Customize the card to match the parent theme */
.project-card {
  background-color: #1a1a2e;
  color: #eaeaea;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
  min-height: 550px;
}

/* Hover effect for the card */
.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Adjust the card cover image */
.project-card .ant-card-cover img {
  height: 350px;
  object-fit: cover;
}

/* Customize the Meta title */
.project-meta .ant-card-meta-title {
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
}
.project-meta .ant-card-meta-title {
  /* Allow text to wrap */
  white-space: normal !important;
  /* Ensure overflow and text-overflow are disabled */
  overflow: visible !important;
  text-overflow: unset !important;
}


/* Customize the Meta description */
.project-meta .ant-card-meta-description {
  color: #cccccc;
}

/* Style the tags */
.project-tags {
  margin-top: 10px;
}

.project-tag {
  background-color: #0f3460;
  color: #eaeaea;
  border: none;
  font-size: 1rem;
  padding: 6px 12px;
  border-radius: 4px;
  margin-top: 10px;
}

.project-tag + .project-tag {
  margin-left: 5px;
}

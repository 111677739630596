.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
    background-color: transparent;
  }
  
  .hero-content {
    text-align: center;
  }
  
  .hero-image img {
    width: 270px; 
    height: 270px; 
    border-radius: 50%;
    border: 5px solid #fff; 
    object-fit: cover; 
    margin-bottom: 1.5rem;
  }
  
  .hero-name {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #fff; 
  }
  
  .uiuc {
    color: #db5e0a;
  }

  .hero-description {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: #ffffffab; 
    max-width: 800px;
  }
  
  .btn {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    background-color: #0077cc;
    color: #fff;
    text-decoration: none;
    border-radius: 25px;
    transition: background-color 0.3s;
  }
  .hero-content {
    animation: fadeIn 1.5s ease-in;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .btn:hover {
    background-color: #005fa3;
  }
  @media (max-width: 600px) {
    .hero-image img {
      width: 150px;
      height: 150px;
    }
  
    .hero-name {
      font-size: 2rem;
    }
  
    .hero-description {
      font-size: 1.2rem;
    }
  }
  